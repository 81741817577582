import nodeClickEvent from './nodeClickEvent';

async function canvasClick(graphInstance, event, store) {
  const mode = store.state.gmodule.edit_mode;
  console.log(event, store.state.newNodeLabel);
  if (mode === 'add_nodes') {
    const payload = {
      x: event.x || null,
      y: event.y || null,
      size: 20,
      type: 'circle',
      label: store.state.newNodeLabel,
      tjType: 'custom-node',
      id: store.state.newNodeLabel, // Generate a unique id,
      tjNodeState: 'custom-node',
      description: { content: '' },
    };

    graphInstance.addItem('node', payload);
  } else if (mode === 'clear_graph') {
    graphInstance.changeData({ nodes: [], edges: [] });
  } else {
    store.dispatch('gmodule/clearStatesByGraphInstance', {
      opacityFlag: false,
      graphInstance,
    });
  }
}

// Define different states
export default {
  nodeClick: nodeClickEvent,
  canvasClick,
  // console.log({ newNodeLabel: store.state.newNodeLabel });
};
