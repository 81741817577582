<template>
  <div id="mainGraphPane" ref="mainGraphPane">
    <div id="mountNode" ref="mountNode">
      <!-- <div v-if="propShow" class="propsShowcase d-flex">
        <p class="px-2" v-for="(value, prop) in propDetail" :key="prop"><span class="propName">{{prop}}</span>: {{value.value}},</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import GraphED from '@/services/graph';
// eslint-disable-next-line import/no-cycle
import { tooltip, toolbar } from '@/common/widgets/GraphExploreWidget/graphPlugins';

export default {
  props: {
    container: {
      type: HTMLElement,
      // required: true,
    },
    graphData: {
      type: Object,
      default: () => ({
        nodes: [],
        edges: [],
      }),
    },
    customGraphConfig: {
      type: Object,
      default: () => ({}),
    },
    customEvent: () => [],
  },

  async mounted() {
    await this.wikiGraphBuild();
    new ResizeObserver(this.resizeLayout).observe(this.$refs.mainGraphPane);
  },

  methods: {
    async wikiGraphBuild() {
      let config = {};
      config.plugins = [tooltip, toolbar];
      config.width = this.$refs.mainGraphPane.scrollWidth;
      config.height = this.$refs.mainGraphPane.scrollHeight;

      // Override custom definition if there are any:
      config = { ...config, ...this.customGraphConfig };
      this.graph = new GraphED(config, this.$store);
      this.graph.init(this.$refs.mountNode);

      this.customEvent().forEach((eventListenerData) => {
        const { itemType, eventName, exec } = eventListenerData;
        this.graph.addEventListener(itemType, eventName, exec);
      });

      this.graph.updateData(this.graphData);
      console.log(this.graph.graphInstance);
    },

    resizeLayout() {
      // console.log('resizeLayout', this.$refs.mainGraphPane.clientHeight, this.$refs.mainGraphPane.clientWidth);
      // this.graph.graphInstance.changeSize(this.$refs.mainGraphPane.clientWidth, this.$refs.mainGraphPane.clientHeight);
      this.graph.graphInstance.layout();
    },
  },

};
</script>

<style lang="scss" scoped>
#mainGraphPane {
    height: 100vh;
    width: 100%;
    background: #333;
  }
  #mountNode {
    height: 100%;
    width: 100%;
  }

</style>
