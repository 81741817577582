import G6 from '@antv/g6';
import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';

export default (graphInstance) => {
  const graphName = graphInstance;
  // graphReactor.exploreInstance.setMode('shortest_path');
  setTimeout(() => {
    // const selectedNodes = graphReactor.exploreInstance.findAllByState(
    //   'node',
    //   'selected',
    // );
    const selectedNodes = GraphEngine.getVertexValue(graphName, { state: 'selected' }, '-bystate');
    console.log('GraphEgine: selectedState', selectedNodes);
    if (selectedNodes.length === 2) {
      const { findShortestPath } = G6.Algorithm;
      // eslint-disable-next-line
            const { path, allPath } = findShortestPath(
        graphInstance.save(),
        selectedNodes[0].getID(),
        selectedNodes[1].getID(),
      );
      const pathNodeMap = {};
      const pathEdgeMap = {};
      path.forEach((id) => {
        // const pathNode = graphReactor.exploreInstance.findById(id);
        const pathNode = GraphEngine.getVertexValue(graphName, id);
        pathNode.toFront();
        GraphEngine.setVertexValue(graphName, {
          item: pathNode,
          state: 'spNode',
          value: true,
        }, '-s');

        // if (index !== (path.length - 1)) {
        //   const edgeNode = GraphEngine.getEdgeValue(graphName, [path[index], path[index + 1]]);
        //   console.log(edgeNode);
        // }
        // graphReactor.exploreInstance.setItemState(
        //   pathNode,
        //   'highlight',
        //   true,
        // );
        pathNodeMap[id] = true;
      });
      GraphEngine.getEdgeValue(graphName, {}, '-all').forEach((edge) => {
        const edgeModel = edge.getModel();
        const { source } = edgeModel;
        const { target } = edgeModel;
        const sourceInPathIdx = path.indexOf(source);
        const targetInPathIdx = path.indexOf(target);
        if (sourceInPathIdx === -1 || targetInPathIdx === -1) return;
        if (Math.abs(sourceInPathIdx - targetInPathIdx) === 1) {
          // graphReactor.exploreInstance.setItemState(
          //   edge,
          //   'highlight',
          //   true,
          // );
          // eslint-disable-next-line
                pathEdgeMap[edge._cfg.id] = true;
          GraphEngine.setEdgeValue(graphName, {
            item: edge, state: 'spEdge', value: true,
          }, '-s');
        } else {
          // graphReactor.exploreInstance.setItemState(
          //   edge,
          //   'inactive',
          //   true,
          // );
          // GraphEngine.setVertexValue(graphName, {
          //   item: edge, state: 'opacityO', value: true,
          // }, '-s');
        }
      });
      GraphEngine.getVertexValue(graphName, {}, '-all').forEach((node) => {
        if (!pathNodeMap[node.getID()]) {
          // graphReactor.exploreInstance.setItemState(
          //   node,
          //   'inactive',
          //   true,
          // );
          GraphEngine.setVertexValue(graphName, {
            item: node, state: 'opacityO', value: true,
          }, '-s');
          GraphEngine.setVertexValue(graphName, [{
            item: node,
            value: {
              labelCfg: {
                style: {
                  opacity: 0.1,
                },
              },
            },
          }], '-u');
        }
      });
      GraphEngine.getEdgeValue(graphName, {}, '-all').forEach((edge) => {
        if (!pathEdgeMap[edge.getID()]) {
          // graphReactor.exploreInstance.setItemState(
          //   edge,
          //   'inactive',
          //   true,
          // );
          GraphEngine.setVertexValue(graphName, {
            item: edge, state: 'opacityO', value: true,
          }, '-s');
        }
      });
    }
  }, 1000);
};
