// eslint-disable-next-line
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import nodeHsl from '@/common/core/Graph/nodeHsl';
// eslint-disable-next-line
import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';
import shortestPath from './shortestPath';

async function nodeClick(graphInstance, event, store) {
  const graphName = graphInstance;
  const nodeItem = event.item;
  const mode = store.state.gmodule.edit_mode;
  const nodeModel = nodeItem.getModel();
  if (mode === 'remove') {
    GraphEngine.removeVertex(graphName, nodeItem);
  } else if (mode === 'hide') {
    nodeItem.hide();
  } else if (mode === 'lock') {
    const hasLocked = nodeItem.hasState('lockedState');
    if (hasLocked) {
      const prevModel = nodeItem.getModel();
      if (prevModel.previousState) {
        exploreOps.updateMyNode(prevModel.previousState);
      }
      GraphEngine.setVertexValue(graphName, { item: nodeItem, state: 'lockedState' }, '-s-cs');
      // graphReactor.exploreInstance.clearItemStates(nodeItem, 'lockedState');
      nodeItem.unlock();
    } else {
      GraphEngine.setVertexValue(graphName, { item: nodeItem, state: 'lockedState', value: true }, '-s');
      nodeItem.lock();
      const myModel = nodeItem.getModel();
      exploreOps.updateMyNode({ ...myModel, previousState: { ...myModel }, size: '30' });
    }
  } else if (mode === 'shortest_path') {
    shortestPath(graphInstance);
  } else if (mode === 'hide_related_nodes') {
    console.log('bhai mai call ho rha hu');
    graphInstance.hideItem(nodeItem);
  } else if (mode === 'show_related_nodes') {
    // eslint-disable-next-line
        const neighborNodes = GraphEngine.getNeighborsValue(
      graphName,
      {
        item: nodeItem,
      },
    );

    const neighborEdges = GraphEngine.getNeighborsValue(
      graphName,
      {
        item: nodeItem,
        neighborType: 'Edges',
        type: 'target',
      },
    );

    if (neighborNodes) {
      neighborNodes.forEach((neighbor) => {
        neighbor.show();
      });
    }

    if (neighborEdges) {
      neighborEdges.forEach((neighbor) => {
        neighbor.show();
      });
    }
  } else if (mode === 'add_relationships') {
    const node = event.item;
    const point = {
      x: event.x,
      y: event.y,
    };
    const model = node.getModel();
    if (store.state.gmodule.addingEdge && store.state.gmodule.edge) {
      // GraphEngine(graphName, {item: sta})
      // graphReactor.exploreInstance.updateItem(store.state.gmodule.edge, {
      //   target: model.id,
      //   label: store.store.state.gmodule.newEdgeLabel,
      // });
      GraphEngine.setEdgeValue(graphName, {
        item: store.state.gmodule.edge,
        value: {
          target: model.id,
          label: store.state.newEdgeLabel,
        },
      }, '-update');
      store.commit('gmodule/SET_EDGE_VAL', null);
      store.commit('gmodule/IS_ADDING_EDGE', false);
    } else {
      const newEdge = GraphEngine.addEdge(graphName, {
        source: model.id,
        target: point,
        label: store.state.newEdgeLabel,
        ...(store.state.isArrowEdge && {
          style: {
            endArrow: true,
          },
        }),
      });
      store.commit('gmodule/SET_EDGE_VAL', newEdge);
      store.commit('gmodule/IS_ADDING_EDGE', true);
    }
  } else if (mode === 'hsl') {
    await store.dispatch('gmodule/clearStatesByGraphInstance', { opacityFlag: true, graphInstance });
    // GraphEngine.setVertexValue(graphName, [{
    //   item: 'Software',
    //   state:
    // }, {
    //   item: 'Mocha',
    // }, {
    //   item: 'Gmail',
    // }], '-s-cs');
    nodeHsl(nodeModel, graphInstance);
    store.dispatch('setCurrentThought', {
      thoughtObject: {
        word: nodeModel.label,
        label: nodeModel.label,
        type: 'string',
        lang: 'en',
      },
      excludeCallList: ['gmodule/exploreGraphFetch'],
    });
  }
}

export default nodeClick;
