import G6 from '@antv/g6';
import graphConfig from '@/config/graphConfig';
import graphListeners from './graphListeners';

class GraphED {
  constructor(config, store) {
    this.config = {
      ...graphConfig,
      ...config,
    };
    this.store = store;
    this.graphInstance = null;
    this.graphData = {
      nodes: [],
      edges: [],
    };
  }

  init(container) {
    const localConfig = {
      ...this.config,
      container,
    };

    this.graphInstance = new G6.Graph(localConfig);
    graphListeners(this.graphInstance, this.store);

    this.graphInstance.data(this.graphData);
    this.graphInstance.render();
    this.graphInstance.updateLayout();
  }

  addEventListener(itemType, eventName, exec) {
    this.graphInstance.on(`${itemType}:${eventName}`, exec);
  }

  updateData(data) {
    this.graphData = data;
    this.graphInstance.changeData(this.graphData);
    this.graphInstance.updateLayout();
  }
}

export default GraphED;
