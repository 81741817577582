import graphEvents from './graphInteraction';

export default (graphInstance, store) => {
  graphInstance.on('node:click', (e) => {
    graphEvents.nodeClick(graphInstance, e, store);
  });

  graphInstance.on('node:dblclick', (e) => {
    console.log(e);
    // Handle node:dblclick event
  });

  graphInstance.on('node:mouseleave', (e) => {
    console.log(e);
    // Handle node:mouseleave event
  });

  graphInstance.on('edge:click', (e) => {
    console.log(e);
    // Handle edge:click event
  });

  graphInstance.on('edge:dblclick', (e) => {
    console.log(e);
    // Handle edge:dblclick event
  });

  graphInstance.on('edge:mouseleave', (e) => {
    console.log(e);
    // Handle edge:mouseleave event
  });

  graphInstance.on('canvas:click', (e) => {
    graphEvents.canvasClick(graphInstance, e, store);
    // Handle canvas:click event
  });

  graphInstance.on('canvas:dblclick', (e) => {
    console.log(e);
    // Handle canvas:dblclick event
  });
};
